import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import SEO from '../components/seo';
import Co240m900kya from '../components/chart-co2-40m-900kya';
import Co2800kyapresent from '../components/chart-co2-800k-present';
import Co210kyapresent from '../components/chart-co2-10k-present';
import Co21959present from '../components/chart-co2-1959-present';
import Co2800kya2100 from '../components/chart-co2-100k-2100';
import Co240mya2100 from '../components/chart-co2-40m-2100';
import { graphql } from 'gatsby';
import Img from "gatsby-image";
import Future35OpenGraphImage from '../images/social-future-35million.jpg';
export const pageQuery = graphql`
  query {
    imageOne: file(relativePath: { eq: "eocene-earth.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3840) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="The Future of 35 Million Years Ago" description="The climate of the near future may look like the distant past." image={'https://storybook.earth' + Future35OpenGraphImage} pageCanonical={'https://storybook.earth/co2-history'} mdxType="SEO" />
    <Img className="story-header-gatsby-img" fluid={props.data.imageOne.childImageSharp.fluid} mdxType="Img" />
    <div className="story-content">
      <p className="txt-low-emph txt-center">Reconstruction of Earth 35 million years ago. Credit: C.R. Scotese and Ian Webster</p>
      <div className="txt-center mvxxl">
    <h1 className="phm">The Future of 35 Million Years Ago</h1>
    <div className="mtm">
        <p className="mbn title-font">By Mike McDearmon</p>
        <p className="mtn txt-low-emph txt-center">Jan 15, 2020 | 7 min read</p>
    </div>
      </div>
      <p>{`Humans are putting CO2 into the atmosphere really fast - about 100 times faster than previous natural increases`}<span className="sup">{` `}{`[1]`}</span>{`. While we know that the planet is warming as a result, we also know that for much of the Earth's history the global climate was warmer than it is today. That might seem comforting, but clues from the fossil record offer glimpses into warmer worlds of the past that would seem like alien planets to a civilization built on 10,000 years of cool stability.`}</p>
      <p>{`One of the closest historic analogs for where we may be heading soon is the Eocene epoch, spanning 55 to 35 million years ago. Still recent in geologic terms, the shape and configuration of the continents would look more or less as it does today, save for a few differences on closer inspection: a narrower Atlantic ocean, the British Isles still clinging to mainland Europe, and the islands of Canada's Arctic Archipelago all huddled together and joined to the Canadian mainland`}<span className="sup">{` `}{`[2]`}</span>{`.`}</p>
      <p>{`The climate of the Eocene, however, was much warmer than Earth as we know it today. The contrast is clearest at the poles, where there were no permanent ice sheets. Instead, the Arctic circle looked more like today's southeastern United States with temperate forests that were home to alligators, snakes, and early primates`}<span className="sup">{` `}{`[3]`}</span>{`.`}</p>
      <p>{`Driving the warm Eocene climate was the high concentration of CO2 in the atmosphere. As noted in the US Global Change Research Program's Fourth National Climate Assessment: "During the Eocene... CO2 levels were between 680 and 1260 ppm `}{`[parts per million]`}{`, or somewhere between two and a half to four and a half times higher than preindustrial levels"`}<span className="sup">{` `}{`[4]`}</span>{`.`}</p>
      <div className="mtxxl txt-center">
        <h3>Atmospheric CO2 concentration 40 million to 900,000 years ago</h3>
        <Co240m900kya mdxType="Co240m900kya" />
        <div className="davi-chart-bottom">
    <div className="x-axis-label">Millions of Years Before Present</div>
    <div className="legend mtm">
        <div className="legend_label mrm">
            <div className="label_line line-primary"></div>
            <div>CO2 ppm</div>
        </div>
        <div className="legend_label mrm">
            <div className="label_line line-green-dashed"></div>
            <div>407.38 ppm (2018 avg)</div>
        </div>
    </div>
        </div>
        <p className="phxl mbxxl caption">This chart shows a gradual decrease in CO2 concentration (measured in parts per million or ppm) from the relative hot house of the Eocene to a more familiar, cooler climate. These estimated CO2 levels come from a 2013 sediment analysis<span className="sup"> [5]</span>.</p>
      </div>
      <p>{`After the Eocene, atmospheric CO2 began to gradually decrease and global temperatures fell as a result. Exactly what caused this drawdown is the subject of recent study, but evidence points to the rise of the Himalayan mountain range as a key contributing factor`}<span className="sup">{` `}{`[6]`}</span>{`. Beginning their ascent 50 million years ago, the Himalayas contributed to global cooling by pulling CO2 out of the atmosphere through chemical weathering and by increasing the planet's solar reflectivity as they buried a vast area of the Earth's surface in snow and ice.`}</p>
      <p>{`The gradual downslope of post-Eocene CO2 levels hit a low point 2.5 million years ago at the start of the Pleistocene, more commonly known as the Ice Age. CO2 levels for the most recent 800,000 years of the Ice Age are shown in the chart below, continuing the CO2 storyline about 100,000 years after the previous chart ends.`}</p>
      <div className="mtxxl txt-center">
        <h3>Atmospheric CO2 concentration 800,000 years ago to present</h3>
        <Co2800kyapresent mdxType="Co2800kyapresent" />
        <div className="davi-chart-bottom">
    <div className="x-axis-label">Years Before Present</div>
    <div className="legend mtm">
        <div className="legend_label mrm">
            <div className="label_line line-primary"></div>
            <div>CO2 ppm</div>
        </div>
    </div>
        </div>
        <p className="phxl mbxxl caption">Showing CO2 levels from 800,000 years ago to the present, this chart combines Antarctic ice core data from 800,000 years ago to 150 years ago<span className="sup"> [7]</span> with measurements at the Mauna Loa observatory recorded from 1959 to today<span className="sup"> [8]</span>.</p>
      </div>
      <p>{`This dataset shows the sawtooth pattern of CO2 levels that occurred throughout much of the Pleistocene. These oscillations in CO2, minor as they are compared to the much larger drawdown from the Eocene, were large enough to swing the planet back and forth between periods of glacial advance and retreat from the poles. The entirety of human civilization has developed during the most recent of these glacial retreats.`}</p>
      <p>{`Because no major changes have occurred in energy output from the sun, the orbit of the Earth, or its physical and chemical activity, the pattern of glacial freeze and thaw was poised to continue before humans began burning fossil fuels for energy. The near-vertical spike in CO2 levels over the last 260 years shows just how exceptional the modern emissions rate is relative to almost a million years of records stored in polar ice.`}</p>
      <div className="mvxxl txt-center">
        <h3>Atmospheric CO2 concentration 10,000 years to present</h3>
        <Co210kyapresent mdxType="Co210kyapresent" />
        <div className="davi-chart-bottom">
    <div className="x-axis-label">Years Before Present</div>
    <div className="legend mtm">
        <div className="legend_label mrm">
            <div className="label_line line-primary"></div>
            <div>CO2 ppm</div>
        </div>
    </div>
        </div>
      </div>
      <p>{`When the timescale of the previous chart is cropped to the last 10,000 years—the birth of human civilization to today—the impact of industrialized fossil fuel emissions is visible. Also striking in this snapshot is the relative stability of the pre-industrial climate.`}</p>
      <p>{`While this period encompasses everything humans have ever accomplished since the dawn of agriculture—a veritable eternity from a human point of view—this stretch of climate stability is so brief in geologic terms that it's hardly visible on the 800,000 year timeline.`}</p>
      <div className="mvxxl txt-center">
        <h3>Atmospheric CO2 concentration 1959 to present</h3>
        <Co21959present mdxType="Co21959present" />
        <div className="davi-chart-bottom">
    <div className="x-axis-label">Year</div>
    <div className="legend mtm">
        <div className="legend_label mrm">
            <div className="label_line line-primary"></div>
            <div>CO2 ppm</div>
        </div>
    </div>
        </div>
      </div>
      <p>{`The meteoric rise of modern CO2 levels stands out on any chart showing more than a few hundred years of history, but when zoomed into the human timescale it's easier to understand why changes can feel slow or undetectable in the span of a single generation.`}</p>
      <p>{`The impacts of emissions are revealing themselves over decades, a rate that tragically masks cause from effect for the majority of the developed world. It doesn't help that CO2 itself is odorless and invisible in the atmosphere. Global warming is a story unfolding in slow motion—a story of relentless, nearly imperceptible changes playing out beyond the human scale.`}</p>
      <div className="mvxxl txt-center">
        <h3>Atmospheric CO2 concentration 800,000 years ago to 2100 (Projected)</h3>
        <Co2800kya2100 mdxType="Co2800kya2100" />
        <div className="davi-chart-bottom">
    <div className="x-axis-label">Years Before Present</div>
    <div className="legend mtm">
        <div className="legend_label mrm">
            <div className="label_line line-primary"></div>
            <div>CO2 ppm</div>
        </div>
        <div className="legend_label mrm">
            <div className="label_line line-secondary"></div>
            <div>CO2 ppm (projected)</div>
        </div>
        <div className="legend_label mrm">
            <div className="label_line line-purple-dashed"></div>
            <div>450 ppm (RCP2.6)</div>
        </div>
        <div className="legend_label mrm">
            <div className="label_line line-teal-dashed"></div>
            <div>550 ppm (RCP4.5)</div>
        </div>
        <div className="legend_label mrm">
            <div className="label_line line-red-dashed"></div>
            <div>936 ppm (RCP8.5)</div>
        </div>
    </div>
        </div>
      </div>
      <p>{`The physics of CO2's heat-absorbing nature having been established in the 1850s, one of the few certainties about the near future is that it will be warmer. Exactly how much warmer is impossible to predict given uncertainties in what humans will do in the future, and how the climate system will react.`}</p>
      <p>{`To illustrate this uncertainty, the chart above plots three scenarios for CO2 concentration in the year 2100 from the US Global Change Research Program called Representative Concentration Pathways (RCPs). In the two lower scenarios, RCP2.6 (purple) and RCP4.5 (teal), CO2 concentration rises to 450 and 550 ppm, respectively. These two scenarios assume drastic measures are taken to decrease CO2 emissions over the next few decades.`}</p>
      <p>{`The highest of these scenarios, RCP8.5 (red), "corresponds to a future where carbon dioxide and methane emissions continue to rise as a result of fossil fuel use, albeit with significant declines in emission growth rates over the second half of the century"`}<span className="sup">{` `}{`[4]`}</span>{`. CO2 concentration under this scenario would reach 936 ppm, with average global temperatures increasing by 5.4°–9.9°F (3°–5.5°C) relative to the 1986-2005 average.`}</p>
      <div className="mvxxl txt-center">
        <h3>Atmospheric CO2 concentration 40 million years ago to 2100 (Projected)</h3>
        <Co240mya2100 mdxType="Co240mya2100" />
        <div className="davi-chart-bottom">
          <div className="x-axis-label">Millions of Years Before Present</div>
          <div className="legend mtm">
    <div className="legend_label mrm">
        <div className="label_line line-primary"></div>
        <div>CO2 ppm</div>
    </div>
    <div className="legend_label mrm">
        <div className="label_line line-secondary"></div>
        <div>CO2 ppm (projected)</div>
    </div>
    <div className="legend_label mrm">
        <div className="label_line line-purple-dashed"></div>
        <div>450 ppm (RCP2.6)</div>
    </div>
    <div className="legend_label mrm">
        <div className="label_line line-teal-dashed"></div>
        <div>550 ppm (RCP4.5)</div>
    </div>
    <div className="legend_label mrm">
        <div className="label_line line-red-dashed"></div>
        <div>936 ppm (RCP8.5)</div>
    </div>
          </div>
        </div>
      </div>
      <p>{`This final chart shows the same three RCP scenarios in context of the last 40 million years of CO2 levels. All three scenarios would mean a return to climate conditions not seen on Earth in over 20 million years—conditions that would gradually melt Arctic ice sheets, change coastlines around the world, force changes to cities and infrastructure, and alter the distribution of food and fresh water around the world.`}</p>
      <p>{`The two lower scenarios would certainly test the adaptability of civilization as we know it, but the higher RCP8.5 scenario would require adjusting to a truly altered world. It would mean a shift from Ice Age to Eocene climate, a reversal of a cooling trend millions of years long in just a handful of human generations`}<span className="sup">{` `}{`[9]`}</span>{`.`}</p>
      <div className="references mvxxl">
          <strong>References</strong>
          {
          /* https://owl.purdue.edu/owl/research_and_citation/ieee_style/reference_list.html */
        }
          <ol className="mts">
            <li>Rebecca Lindsey, <a href="https://www.climate.gov/news-features/understanding-climate/climate-change-atmospheric-carbon-dioxide" target="blank">Climate Change: Atmospheric Carbon Dioxide</a>, Climate.gov, 2019</li>
            <li>C.R. Scotese and Ian Webster, <a href="https://dinosaurpictures.org/ancient-earth#35" target="blank">Ancient Earth Globe</a>, Accessed: Jan 6, 2020</li>
            <li>Guy J. Harrington, Jaelyn Eberle, Ben A. Le-Page, Mary Dawson and J. Howard Hutchinson, <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3282332/" target="blank">Arctic plant diversity in the Early Eocene greenhouse</a>, The Royal Society B: Biological Sciences, 2011</li>
            <li>Hayhoe, K., J. Edmonds, R.E. Kopp, A.N. LeGrande, B.M. Sanderson, M.F. Wehner, and D.J. Wuebbles, <a href="https://science2017.globalchange.gov/downloads/CSSR_Ch4_Climate_Models_Scenarios_Projections.pdf" target="blank">2017: Climate models, scenarios, and projections. In: Climate Science Special Report: Fourth National Climate
Assessment, Volume I</a>, US Global Change Research Program, 2017</li>
            <li>Yi Ge Zhang, Mark Pagani, Zhonghui Liu, Steven M. Bohaty and Robert DeConto, <a href="https://royalsocietypublishing.org/doi/full/10.1098/rsta.2013.0096#d3e1337" target="blank">A 40-million-year history of atmospheric CO2</a>, The Royal Society A: Mathematical, Physical and Engineering Sciences, 2013</li>
            <li>Holli Riebeek, <a href="https://earthobservatory.nasa.gov/features/CarbonCycle" target="blank">The Carbon Cycle</a>, NASA Earth Observatory, 2011</li>
            <li>Dieter Luthi, et al., <a href="https://www1.ncdc.noaa.gov/pub/data/paleo/icecore/antarctica/epica_domec/edc-co2-2008.txt" target="blank">EPICA Dome C Ice Core 800KYr Carbon Dioxide Data</a>, NOAA/NCDC Paleoclimatology Program, 2008</li>
            <li>Ed Dlugokencky and Pieter Tans, <a href="https://www.esrl.noaa.gov/gmd/ccgg/trends/gl_data.html" target="blank">Mauna Loa CO2 annual mean data</a>, NOAA/ESRL, 2019</li>
            <li>K. D. Burke,  J. W. Williams, M. A. Chandler, A. M. Haywood, D. J. Lunt, B. L. Otto-Bliesner, <a href="https://www.pnas.org/content/115/52/13288" target="blank">Pliocene and Eocene provide best analogs for near-future climates</a>, Proceedings of the National Academy of Sciences of the United States of America, 2018</li>
          </ol>
        </div>
    </div>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      